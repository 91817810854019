import React, {useState, useRef} from "react";
import LoadingModal from "../../lib/LoadingModal";

import ReCAPTCHA from 'react-google-recaptcha';

import './contact.css';
import './responsive.css';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [formErrorsName, setFormErrorsName] = useState('');
    const [formErrorsEmail, setFormErrorsEmail] = useState('');
    const [formErrorsSubject, setFormErrorsSubject] = useState('');
    const [formErrorsMessage, setFormErrorsMessage] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        token: ''
    });

    const recaptchaRef = useRef(null);

    const onCaptchaChange = (token) => {
        setCaptchaToken(token);
        formData.token = token;
    };

    // Función para validar cada campo y establecer errores
    const validateField = (name, value) => {
        let error = '';
        switch (name) {
        case 'name':
            error = '';
            if (!value) {
                error = 'Nombre es obligatorio';
            } else if (value.length < 2) {
                error = 'El Nombre debe tener al menos 2 caracteres';
            } else if (value.length > 100) {
                error = 'El Nombre no puede tener más de 100 caracteres';
            }

            if(error.length > 0){
                setFormErrorsName(error);
            }else{
                setFormErrorsName('');
            }
            break;
        case 'email':
            error = '';
            if (!value) {
                error = 'Email es obligatorio';
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                error = 'El formato del email no es válido';
            } else if(value.length > 100) {
                error = 'El Email no puede tener más de 100 caracteres';
            }

            if(error.length > 0){
                setFormErrorsEmail(error);
            }else{
                setFormErrorsEmail('');
            }
            break;
        case 'subject':
            error = '';
            if (!value) {
                error = 'Asunto es obligatorio';
            } else if (value.length < 2) {
                error = 'El Asunto debe tener al menos 2 caracteres';
            } else if (value.length > 100) {
                error = 'El Asunto no puede tener más de 100 caracteres';
            }

            if(error.length > 0){
                setFormErrorsSubject(error);
            }else{
                setFormErrorsSubject('');
            }
            break;
        case 'message':
            error = '';
            if (!value) {
                error = 'Mensaje es obligatorio';
            } else if (value.length < 10) {
                error = 'El mensaje debe tener al menos 10 caracteres';
            } else if (value.length > 1000) {
                error = 'El Asunto no puede tener más de 1000 caracteres';
            }

            if(error.length > 0){
                setFormErrorsMessage(error);
            }else{
                setFormErrorsMessage('');
            }
            break;
        default:
            break;
        }
    };

    // Actualiza el estado y valida el campo correspondiente
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
        validateField(name, value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!captchaToken) {
            alert("Por favor, completa el CAPTCHA");
            return;
        }

        setLoading(true);

        let hasErrors = false;
        Object.keys(formData).forEach((key) => {
            validateField(key, formData[key]);
            if (formErrorsName[key] || formErrorsEmail[key] || formErrorsSubject[key] || formErrorsMessage[key]) hasErrors = true;
        });

        if (hasErrors) {
            alert("Por favor corrige los errores antes de enviar el formulario");
            return;
        }

        try {
            formData.token = captchaToken;
            const response = await fetch('https://www.api.jonatanporteiro.uy/sendMessage', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });
        
            const data = await response.json();
    
            if (data) {
                alert(data.data);
                setFormData({ name: '', email: '', subject: '', message: '', token: '' }); // Limpiar formulario
                recaptchaRef.current.reset();
                setCaptchaToken(null); // Resetear captcha
            } else {
                alert("Error al enviar el formulario. Intenta nuevamente.");
            }
        } catch (error) {
            alert("Error al enviar el formulario");
        } finally{
            setLoading(false);
        }
    };

    return(
        <section className="content__contact">
                <header className="contact__header">
                    <h2 className="contact__title">
                    <span className="contact__color">Con</span>tacto
                    </h2>
                </header>

                <div className="contact__container">
                    <section className="contact__form-box">
                        <header className="contact__form-header">
                            <h3 className="form-header__title">
                                ¡Contactemos!
                            </h3>
                        </header>

                        <form onSubmit={handleSubmit} className="contact__form">
                            <div className="form__container">
                                <section className="form__top">
                                    <div className="form__group form__group--1">
                                        <input type="text" className="form__input" name="name" placeholder="Nombre" value={formData.name} onChange={handleChange} required />
                                        <label htmlFor="name" className={`form__label ${formErrorsName ? 'form__label--error' : ''}`}>{formErrorsName || 'Nombre'}</label>
                                    </div>

                                    <div className="form__group form__group--2">
                                        <input type="email" className="form__input" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                                        <label htmlFor="email" className={`form__label ${formErrorsEmail ? 'form__label--error' : ''}`}>{formErrorsEmail || 'Email'}</label>
                                    </div>

                                    <div className="form__group form__group--3">
                                        <input type="text" className="form__input" name="subject" placeholder="Asunto" value={formData.subject} onChange={handleChange} required />
                                        <label htmlFor="subject" className={`form__label ${formErrorsSubject ? 'form__label--error' : ''}`}>{formErrorsSubject || 'Asunto'}</label>
                                    </div>
                                </section>

                                <section className="form__bottom">
                                    <div className="form__group form__group--textarea">
                                        <textarea className="form__input form__input--textarea" name="message" placeholder="Mensaje" value={formData.message} onChange={handleChange} required></textarea>
                                        <label htmlFor="message" className={`form__label ${formErrorsMessage ? 'form__label--error' : ''}`}>{formErrorsMessage || 'Mensaje'}</label>
                                    </div>
                                </section>

                                <ReCAPTCHA
                                    className="recaptcha-container"
                                    sitekey="6LcygHUqAAAAANtk3mjrib01mH0yOQ8ffjnHn7D8"
                                    onChange={onCaptchaChange}
                                    ref={recaptchaRef}
                                />
                            </div>

                            <input type="submit" value="Enviar mensaje" className="form__button" />
                            <LoadingModal className="modal-loading" show={loading} />
                        </form>
                    </section>
                </div>
            </section>
    );
}

export default Contact;